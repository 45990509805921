<template>
  <div>
    <a-tabs class="bill-details-tab" :animated="false" v-model:activeKey="activeTab" @tabClick="tabClick" type="editable-card" hide-add>
      <a-tab-pane class="bill-details-tab-pane" v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">
        <div v-if="pane.key === 'payment'" :class="{ 'text-center': loading }">
          <a-spin v-if="loading" class="p-5" />
          <div v-else>
            <div v-if="payment">
              <div class="row mx-2">
                <div class="col-md-6 px-0">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="payment?.store?.name">
                        <template #title>
                          <div>Заведение</div>
                        </template>
                        <template #avatar>
                          <img :src="payment.store?.logo_url" class="store-logo" alt="" />
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
                <div class="col-md-6">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="payment.employee?.name">
                        <template #title>
                          <div>Сотрудник</div>
                        </template>
                        <template #avatar>
                          <a-avatar shape="square" class="cover" :src="getAvatar(payment.employee?.photo_url)" />
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <a-comment class="review-details-comment">
                    <template #actions>
                      <div class="comment-like" key="comment-basic-like">
                        <div class="mb-2" style="line-height: 22px; font-size: 14px">
                          <label for="null">Счет:&nbsp;</label>
                          <i style="display: block; height: 18px; width: 20px; float: left; margin-right: 8px">
                            <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#595c97" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0 0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z"/></svg>
                          </i>
                          <span v-if="payment['bill_amount']">
                            <span>{{ formattedBalance(payment['bill_amount'], true) }}</span>
                          </span>
                          <span v-else>Без счета</span>
                        </div>
                        <div class="mb-2" style="line-height: 22px; font-size: 14px">
                          <label for="null">Чаевые:&nbsp;</label>
                          <i style="display: block; height: 18px; width: 20px; float: left; margin-right: 8px">
                            <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#595c97" d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>
                          </i>
                          <span v-if="payment['tips_amount']">
                            <span v-if="payment?.commission">{{ formattedBalance(payment['tips_amount'] - payment.commission, true) }}</span>
                            <span v-else>{{ formattedBalance(payment['tips_amount'], true) }}</span>
                          </span>
                          <span v-else>Без чаевых</span>
                        </div>
                        <div class="mb-2" style="line-height: 22px; font-size: 14px" v-if="payment['delivery_amount']">
                          <label for="null">Доставка:&nbsp;</label>
                          <i style="display: block; height: 18px; width: 20px; float: left; margin-right: 8px">
                            <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#595c97" d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                          </i>
                          <span>{{ formattedBalance(payment['delivery_amount'], true) }}</span>
                        </div>
                        <div class="mb-2" style="line-height: 22px; font-size: 14px">
                          <label for="null"><strong>Сумма списания:&nbsp;</strong></label>
                          <i style="display: block; height: 18px; width: 20px; float: left; margin-right: 8px">
                            <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#595c97" d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"/></svg>
                          </i>
                          <strong>{{ formattedBalance(payment['debit_amount'], true) }}</strong>
                        </div>
                      </div>
                    </template>
                    <template #author>
                      <a-typography-title :level="5">Оплата</a-typography-title>
                    </template>
                    <template #content>
                      <p>{{ payment.comment }}</p>
                    </template>
                  </a-comment>
                </div>
                <div class="col-md-6">
                  <a-comment class="review-details-comment" v-if="payment.user">
                    <template #actions>
                      <div class="comment-like" key="comment-basic-like">
                        <div class="clearfix w-100 mb-2" style="line-height: 22px; font-size: 14px">
                          <label for="null"><span style="text-transform: capitalize">{{ payment.payment_system }}</span>&nbsp;<span v-if="payment['user_card_pan']">({{ formattedPan(payment['user_card_pan']) }})</span></label>
                          <i style="display: block; height: 18px; width: 20px; float: left; margin-right: 8px">
                            <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#595c97" d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"/></svg>
                          </i>
                        </div>
                        <div class="clearfix w-100 mb-2" style="line-height: 22px; font-size: 14px" v-if="payment['user']?.name && payment['user']?.name !== '-'">
                          <label for="null">{{ payment['user'].name }}</label>
                          <i style="display: block; height: 18px; width: 20px; float: left; margin-right: 8px">
                            <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#595c97" d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
                          </i>
                        </div>
                        <div class="clearfix w-100 mb-2" style="line-height: 22px; font-size: 14px">
                          <a
                            v-if="payment.user?.phone"
                            :href="'tel:' + payment.user?.phone"
                            :class="{
                              'text-muted disabled': !payment.user?.phone,
                              'text-secondary': payment.user?.phone
                            }"
                            class="comment-dislike phone-link"
                            key="comment-basic-dislike"
                          >
                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#595c97" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm90.7 96.7c9.7-2.6 19.9 2.3 23.7 11.6l20 48c3.4 8.2 1 17.6-5.8 23.2L168 231.7c16.6 35.2 45.1 63.7 80.3 80.3l20.2-24.7c5.6-6.8 15-9.2 23.2-5.8l48 20c9.3 3.9 14.2 14 11.6 23.7l-12 44C336.9 378 329 384 320 384C196.3 384 96 283.7 96 160c0-9 6-16.9 14.7-19.3l44-12z"/></svg>
                            <span>{{ formattedPhone(payment.user.phone) }}</span>
                          </a>
                        </div>
                      </div>
                    </template>
                    <template #author>
                      <a-typography-title :level="5">Плательщик</a-typography-title>
                    </template>
                  </a-comment>
                </div>
              </div>
              <hr>
              <div class="row mx-2">
                <div class="col-md-6 px-0">
                  <a-tooltip placement="topLeft">
                    <template #title>
                      <div v-if="payment?.status === 'revert'">Отменен: {{ payment?.['reversed_time'] }}</div>
                      <div v-if="payment?.status === 'revert' && payment?.['reversed']">Отменил: {{ payment?.['reversed'] }}</div>
                      <div v-if="payment?.status === 'revert' && payment?.['reverse_comment']">Комментарий: {{ payment?.['reverse_comment'] }}</div>
                    </template>
                    <a-list class="cursor-pointer">
                      <a-list-item>
                        <a-list-item-meta :description="statusesFilter.find(status => status.value === payment?.status)?.label">
                          <template #title>
                            <div>Статус</div>
                          </template>
                          <template #avatar>
                            <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#595c97" d="M112 112c0 35.3-28.7 64-64 64V336c35.3 0 64 28.7 64 64H464c0-35.3 28.7-64 64-64V176c-35.3 0-64-28.7-64-64H112zM0 128C0 92.7 28.7 64 64 64H512c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM176 256a112 112 0 1 1 224 0 112 112 0 1 1 -224 0zm80-48c0 8.8 7.2 16 16 16v64h-8c-8.8 0-16 7.2-16 16s7.2 16 16 16h24 24c8.8 0 16-7.2 16-16s-7.2-16-16-16h-8V208c0-8.8-7.2-16-16-16H272c-8.8 0-16 7.2-16 16z"/></svg>
                          </template>
                        </a-list-item-meta>
                      </a-list-item>
                    </a-list>
                  </a-tooltip>
                </div>
                <div class="col-md-6">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="typesFilter.find(type => type.value === payment?.type)?.label">
                        <template #title>
                          <div>Тип</div>
                        </template>
                        <template #avatar>
                          <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#595c97" d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5V80C0 53.5 21.5 32 48 32H197.5c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
              </div>
              <div class="row mx-2">
                <div class="col-md-6 px-0">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="payment['push_sent_at'] ? formattedDate(payment['push_sent_at']) : 'Нет данных'">
                        <template #title>
                          <div>Время отправки PUSH</div>
                        </template>
                        <template #avatar>
                          <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#595c97" d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v25.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm0 96c61.9 0 112 50.1 112 112v25.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V208c0-61.9 50.1-112 112-112zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/></svg>
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
                <div class="col-md-6">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="formattedDate(payment?.['payment_time'] ? payment?.['payment_time'] : payment?.['added_on'])">
                        <template #title>
                          <div>Время оплаты</div>
                        </template>
                        <template #avatar>
                          <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#595c97" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
              </div>
              <div class="row mx-2">
                <div class="col-md-6 px-0">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta>
                        <template #description>
                          <!-- eslint-disable vue/no-v-html -->
                          <span v-html="getClearingStatus(payment)"></span>
                          <!--eslint-enable-->
                        </template>
                        <template #title>
                          <div>Статус возмещения</div>
                        </template>
                        <template #avatar>
                          <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#595c97" d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z"/></svg>
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
                <div class="col-md-6" v-if="payment['reversed_time']">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="formattedDate(payment?.['reversed_time'])">
                        <template #title>
                          <div>Время возврата</div>
                        </template>
                        <template #avatar>
                          <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#595c97" d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/></svg>
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
              </div>
            </div>
            <hr>
            <a-button
              @click="close"
              type="default"
              class="float-right"
            >Закрыть</a-button>
            <a-button @click="showCancelModal(payment)" v-if="payment?.paid && !['manager', 'support'].includes(role)" danger>Отменить платеж</a-button>
          </div>
        </div>
        <div v-if="pane.key === 'review'" :class="{ 'text-center': loading }">
          <a-spin v-if="loading" class="p-5" />
          <div v-else>
            <div v-if="review">
              <div class="row mx-2">
                <div class="col-md-6 px-0">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="payment?.store?.name">
                        <template #title>
                          <div>Заведение</div>
                        </template>
                        <template #avatar>
                          <img :src="payment.store?.logo_url" class="store-logo" alt="" />
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
                <div class="col-md-6">
                  <a-list>
                    <a-list-item>
                      <a-list-item-meta :description="review.employee?.name">
                        <template #title>
                          <div>Сотрудник</div>
                        </template>
                        <template #avatar>
                          <a-avatar shape="square" class="cover" :src="getAvatar(review.employee?.photo_url)" />
                        </template>
                      </a-list-item-meta>
                    </a-list-item>
                  </a-list>
                </div>
              </div>
              <hr>
              <a-comment class="review-details-comment">
                <template #actions>
                  <span class="comment-like" key="comment-basic-like">
                    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.8333 0.166626H2.16665C1.2879 0.166626 0.59123 0.871209 0.59123 1.74996L0.583313 11.25C0.583313 12.1287 1.2879 12.8333 2.16665 12.8333H14.8333C15.7121 12.8333 16.4166 12.1287 16.4166 11.25V1.74996C16.4166 0.871209 15.7121 0.166626 14.8333 0.166626ZM14.8333 11.25H2.16665V6.49996H14.8333V11.25ZM14.8333 3.33329H2.16665V1.74996H14.8333V3.33329Z" fill="#616161"/>
                    </svg>
                    <span v-if="review.tips">
                      <span v-if="review?.payment?.commission">{{ formattedBalance(review.tips - (review.payment.commission / 100)) }}</span>
                      <span v-else>{{ formattedBalance(review.tips) }}</span>
                    </span>
                    <span v-else>Без чаевых</span>
                  </span>
                  <a
                    v-if="review.user?.phone"
                    :href="'tel:' + review.user?.phone"
                    :class="{
                      'text-muted disabled': !review.user?.phone,
                      'text-success': review.user?.phone
                    }"
                    class="comment-dislike phone-link"
                    key="comment-basic-dislike"
                  >
                    <span>{{ formattedPhone(review.user.phone) }}</span>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.24083 6.54208C4.38083 8.7825 6.2175 10.6112 8.45792 11.7592L10.1996 10.0175C10.4133 9.80375 10.73 9.7325 11.0071 9.8275C11.8938 10.1204 12.8517 10.2788 13.8333 10.2788C14.2688 10.2788 14.625 10.635 14.625 11.0704V13.8333C14.625 14.2688 14.2688 14.625 13.8333 14.625C6.39958 14.625 0.375 8.60042 0.375 1.16667C0.375 0.73125 0.73125 0.375 1.16667 0.375H3.9375C4.37292 0.375 4.72917 0.73125 4.72917 1.16667C4.72917 2.15625 4.8875 3.10625 5.18042 3.99292C5.2675 4.27 5.20417 4.57875 4.9825 4.80042L3.24083 6.54208Z" fill="#616161"/>
                    </svg>
                  </a>
                  <!--                  <a v-else href="#" @click.prevent="" class="comment-dislike phone-link text-muted disabled">-->
                  <!--                    <span>Позвонить</span>-->
                  <!--                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                  <!--                      <path d="M3.24083 6.54208C4.38083 8.7825 6.2175 10.6112 8.45792 11.7592L10.1996 10.0175C10.4133 9.80375 10.73 9.7325 11.0071 9.8275C11.8938 10.1204 12.8517 10.2788 13.8333 10.2788C14.2688 10.2788 14.625 10.635 14.625 11.0704V13.8333C14.625 14.2688 14.2688 14.625 13.8333 14.625C6.39958 14.625 0.375 8.60042 0.375 1.16667C0.375 0.73125 0.73125 0.375 1.16667 0.375H3.9375C4.37292 0.375 4.72917 0.73125 4.72917 1.16667C4.72917 2.15625 4.8875 3.10625 5.18042 3.99292C5.2675 4.27 5.20417 4.57875 4.9825 4.80042L3.24083 6.54208Z" fill="#616161"/>-->
                  <!--                    </svg>-->
                  <!--                  </a>-->
                </template>
                <template #author><a class="review-details-username">{{ formattedUsername(review.user?.name) }}</a></template>
                <template #content>
                  <p>{{ review.comment }}</p>
                </template>
                <template #datetime>
                  <span v-for="i in review.rating" :key="i">
                    <StarTwoTone :style="{ fontSize: '18px' }" :two-tone-color="'#00b200'" />
                  </span>
                  {{ formattedDate(review.added_on) }}
                </template>
              </a-comment>
            </div>
            <hr>
            <a-button
              @click="close"
              type="default"
              class="float-right"
            >Закрыть</a-button>
            <a-button @click="showCancelModal(review)" v-if="review?.paid && !['manager', 'support'].includes(role)" danger>Отменить платеж</a-button>
          </div>
        </div>
        <div v-if="pane.key === 'cheque'" class="grid">
          <a-card :title="null" class="order-details-card">
            <div class="clearfix">
              <div
                class="order-number float-left"
              >Счет № {{ orderDetails.number ? orderDetails.number : orderDetails.Number }}</div>
              <div class="float-right">
                <a-avatar class="contain cheque-store-logo" :src="payment.store?.logo_url"></a-avatar>
              </div>
            </div>
            <a-list
              class="order-details-list"
              v-if="orderDetails.items || orderDetails.Items" item-layout="horizontal"
              :data-source="orderDetails.items ? orderDetails.items : orderDetails.Items">
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta>
                    <template #description>
                      <div class="product-quantity">
                        {{ item.count }} x {{ formattedBalance(item.price, true) }}
                      </div>
                    </template>
                    <template #title>
                      <div class="clearfix">
                        <div class="float-left product-name">{{ item.name }}</div>
                        <div class="float-right product-price">{{ formattedBalance(item.price * item.count, true) }}</div>
                      </div>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
              <a-list-item class="order-details-total">
                <a-list-item-meta>
                  <template #description>
                    <!--
                    <div class="clearfix">
                      <div class="float-left">Счет:</div>
                      <div class="float-right">{{ formattedBalance(orderDetails.orderSum) }}</div>
                    </div>
                    <div class="clearfix">
                      <div class="float-left">Доставка:</div>
                      <div class="float-right">{{ formattedBalance(orderDetails.orderSum) }}</div>
                    </div>
                    -->
                  </template>
                  <template #title>
                    <div class="clearfix">
                      <div class="float-left">Итого:</div>
                      <div class="float-right">{{
                          orderDetails.orderSum
                            ? formattedBalance(orderDetails.orderSum, true)
                            : formattedBalance(orderDetails.OrderSum, true)
                        }}</div>
                    </div>
                  </template>
                </a-list-item-meta>
              </a-list-item>
              <a-list-item class="order-details-address" v-if="orderDetails.address || orderDetails.Address">
                <a-list-item-meta>
                  <template #description>
                    <div id="map"></div>
                    {{ orderDetails.address ? orderDetails.address : orderDetails.Address }}
                  </template>
                  <template #title>
                    <div class="address-title">Адрес доставки</div>
                  </template>
                </a-list-item-meta>
              </a-list-item>
              <div v-if="role === 'admin'">
                <div v-if="payment['status_response']"><pre>{{ payment['status_response'] }}</pre></div>
                <a
                  href="#"
                  @click.prevent="checkStatus(payment['id'])"
                  class="btn btn-outline-light mt-3"
                >
                  <a-spin v-if="checkLoading" size="small" class="mr-2" />
                  <span>Проверить статус платежа</span>
                </a>
              </div>
              <div v-if="payment['payment_response']">
                <div v-if="!showMoreDetails && role === 'admin' && payment.details">
                  <div class="text-center mt-3">
                    <a href="#" class="btn btn-outline-info" @click.prevent="showMoreDetails = true">Лог</a>
                  </div>
                </div>
                <div v-if="showMoreDetails" class="mt-3">
                  <div v-if="payment['payment_response']?.['isSuccess']">Счет успешно закрыт</div>
                  <div v-else class="text-danger" :style="{ opacity: closeLoading ? 0.3 : 1 }">Error: {{ payment['payment_response']?.['message'] }}</div>
                  <a
                    href="#"
                    @click.prevent="closeOrder(payment['id'])"
                    class="btn btn-outline-light mt-3"
                    v-if="!payment['payment_response']?.['isSuccess']"
                  >
                    <a-spin v-if="closeLoading" size="small" class="mr-2" />
                    <span>Закрыть счет на кассе</span>
                  </a>
                </div>
              </div>
            </a-list>
          </a-card>
        </div>
        <div v-if="pane.key === 'tax'">
          {{ payment.tax.fm_num }}<br/>
          <span>Номер чека: {{ payment.tax.id }}<br/></span>
          <span v-if="payment.tax.receipt">{{ payment.tax.receipt?.Time }}<br/></span>
          <span v-else>{{ payment.tax.added_on }}</span>
          <table class="table w-100 mt-4">
            <thead>
            <tr v-if="payment.tax['receipt']">
              <th>Номи</th>
              <th class="text-right">Narxi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in payment.tax.receipt?.Items" :key="key">
              <td>
                <strong>{{ item.Name }}</strong><br/>
                QQS qiymati<br/>
                Chegirma/Boshqa<br/>
                Shtrix kodi<br/>
                MXIK kodi<br/>
                Komitent STIRi<br/>
              </td>
              <td class="text-right">
                <strong>{{ formattedBalance(item.Price, true) }}</strong><br/>
                {{ formattedBalance(item.VAT, true) }}<br/>
                {{ formattedBalance(item.Discount, true) }}/{{ formattedBalance(item.Other, true) }}<br/>
                {{ item.Barcode }}<br/>
                {{ item.SPIC }}<br/>
                {{ item.CommissionInfo.TIN ?? item.CommissionInfo.PINFL }}<br/>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr v-if="payment.tax.receipt" >
              <td>Naqd pul</td>
              <td class="text-right">{{ formattedBalance(payment.tax.receipt?.ReceivedCash, true) }}</td>
            </tr>
            <tr v-if="payment.tax.receipt" >
              <td>Bank kartalari</td>
              <td class="text-right">{{ formattedBalance(payment.tax.receipt?.ReceivedCard, true) }}</td>
            </tr>
            <tr v-if="payment.tax.receipt" >
              <th>JAMI TO'LOV</th>
              <th class="text-right">{{ formattedBalance(payment.tax.receipt?.ReceivedCard + payment.tax.receipt?.ReceivedCash, true) }}</th>
            </tr>
            <tr v-if="payment.tax.receipt" >
              <td>Umumiy QQS qiymati</td>
              <td class="text-right">{{ formattedBalance(payment.tax.receipt?.TotalVAT, true) }}</td>
            </tr>
            <tr>
              <td>QR URL</td>
              <td class="text-right">
                <a-tooltip :title="payment.tax.qr_url">
                  <a class="btn btn-outline-secondary qr-url" target="_blank" :href="payment.tax.qr_url">{{ payment.tax.qr_url }}</a>
                </a-tooltip>
              </td>
            </tr>
            <tr v-if="!showMoreReceipt && role === 'admin' && payment.tax['receipt']">
              <td colspan="2" class="text-center">
                <a href="#" class="btn btn-outline-info" @click.prevent="showMoreReceipt = true">Подробнее</a>
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                Ответ OFD:
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                <pre style="white-space: break-spaces;">{{ payment.tax.response?.body }}</pre>
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                Запрос OFD:
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                <pre style="white-space: break-spaces;">{{ payment.tax?.receipt }}</pre>
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2" class="text-center">
                <a href="#" class="btn btn-outline-info" @click.prevent="showMoreReceipt = false">Свернуть</a>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
        <div v-if="pane.key === 'refunded'">
          {{ payment['refunded'].fm_num }}<br/>
          Номер чека: {{ payment['refunded'].receipt.ReceiptId }}<br/>
          {{ payment['refunded'].receipt.Time }}<br/>
          <table class="table w-100 mt-4">
            <thead>
            <tr>
              <th>Номи</th>
              <th class="text-right">Narxi</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in payment['refunded'].receipt.Items" :key="key">
              <td>
                <strong>{{ item.Name }}</strong><br/>
                QQS qiymati<br/>
                Chegirma/Boshqa<br/>
                Shtrix kodi<br/>
                MXIK kodi<br/>
                Komitent STIRi<br/>
              </td>
              <td class="text-right">
                <strong>{{ formattedBalance(item.Price, true) }}</strong><br/>
                {{ formattedBalance(item.VAT, true) }}<br/>
                {{ formattedBalance(item.Discount, true) }}/{{ formattedBalance(item.Other, true) }}<br/>
                {{ item.Barcode }}<br/>
                {{ item.SPIC }}<br/>
                {{ item.CommissionInfo.TIN ?? item.CommissionInfo.PINFL }}<br/>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td>Naqd pul</td>
              <td class="text-right">{{ formattedBalance(payment['refunded'].receipt.ReceivedCash, true) }}</td>
            </tr>
            <tr>
              <td>Bank kartalari</td>
              <td class="text-right">{{ formattedBalance(payment['refunded'].receipt.ReceivedCard, true) }}</td>
            </tr>
            <tr>
              <th>JAMI TO'LOV</th>
              <th class="text-right">{{ formattedBalance(payment['refunded'].receipt.ReceivedCard + payment['refunded'].receipt.ReceivedCash, true) }}</th>
            </tr>
            <tr>
              <td>Umumiy QQS qiymati</td>
              <td class="text-right">{{ formattedBalance(payment['refunded'].receipt.TotalVAT, true) }}</td>
            </tr>
            <tr>
              <td>QR URL</td>
              <td class="text-right">
                <a-tooltip :title="payment['refunded'].qr_url">
                  <a class="btn btn-outline-secondary qr-url" target="_blank" :href="payment['refunded'].qr_url">{{ payment['refunded'].qr_url }}</a>
                </a-tooltip>
              </td>
            </tr>
            <tr v-if="!showMoreReceipt && role === 'admin'">
              <td colspan="2" class="text-center">
                <a href="#" class="btn btn-outline-info" @click.prevent="showMoreReceipt = true">Подробнее</a>
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                Ответ OFD:
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                <pre style="white-space: break-spaces;">{{ payment['refunded'].response.body }}</pre>
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                Запрос OFD:
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2">
                <pre style="white-space: break-spaces;">{{ payment['refunded'].receipt }}</pre>
              </td>
            </tr>
            <tr v-if="showMoreReceipt">
              <td colspan="2" class="text-center">
                <a href="#" class="btn btn-outline-info" @click.prevent="showMoreReceipt = false">Свернуть</a>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
        <div v-if="pane.key === 'invoice'">
          <pre>{{ payment['invoice_data'] }}</pre>
        </div>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model:visible="cancelModal"
      title="Комментарий"
      cancel-text="Закрыть"
      ok-text="Отменить платеж"
      :confirm-loading="cancelLoading"
      @ok="cancelPayment"
      @cancel="closeCancelModal"
    >
      <a-textarea
        class="form-control"
        v-model:value="commentText"
      />
      <p style="color: red">{{ hint }}</p>
      <a-form-item v-if="role === 'admin'" label="Отменить без возврата средств">
        <a-switch
          checked-children="Да"
          un-checked-children="Нет"
          v-model:checked="cancelWithoutRequest"
        />
      </a-form-item>
    </a-modal>
  </div>
</template>

<script setup>
import { StarTwoTone } from '@ant-design/icons-vue';
import OrderDetails from "@/models/OrderDetails";
import {onMounted, ref, computed} from "vue";
import {notification} from "ant-design-vue";
import apiClient from "@/services/axios";
import moment from "moment/moment";
import {useStore} from "vuex";
import {mask} from "maska";

const props = defineProps({
  id: {
    type: Number,
    default: ref(0),
  },
})
const statusesFilter = ref([
  { value: '', label: "Все" },
  { label: 'Оплачен', value: 'success' },
  { label: 'Не оплачен', value: 'draft' },
  { label: 'Ошибка', value: 'error' },
  { label: 'Отменён', value: 'revert' },
  { label: 'Процес оплаты', value: 'new' },
  { label: 'Процес отмены', value: 'prerevert' },
])
const typesFilter = ref([
  { label: 'Обычная', value: 'default' },
  { label: 'Быстрая оплата', value: 'fast_pay' },
  { label: 'Оплата по QR-коду', value: 'scan_pay' },
])
const commentText = ref('');
const closeLoading = ref(false);
const checkLoading = ref(false);
const hint = ref('');
const cancelLoading = ref(false);
const cancelModalId = ref(0);
const cancelWithoutRequest = ref(false);
const cancelModal = ref(false);
const currentPayment = ref(null);
const emit = defineEmits(['close'])

const store = useStore()
const role = computed(() => store.getters['user/user'].role)

let orderDetails = ref({}),
  payment = ref(null),
  review = ref(null),
  activeTab = ref('payment'),
  loading = ref(false),
  showMoreReceipt = ref(false),
  showMoreDetails = ref(false);

const getAvatar = src => {
  if (!src) return 'https://cdn.multicard.uz/storage/29e/e8576059ab6c74182ab7797992857.png';
  return src;
}
const checkStatus = id => {
  checkLoading.value = true
  apiClient.post(`/report/payment-status?id=${id}`).then(({ data: response }) => {
    payment.value.status_response = JSON.parse(response.data['payment_response'])
  }).finally(() => {
    closeLoading.value = false
  })
  checkLoading.value = false
}
const closeOrder = id => {
  closeLoading.value = true
  apiClient.post(`/report/payment-close?id=${id}`).then(({ data: response }) => {
    payment.value.payment_response = JSON.parse(response.data['payment_response'])
  }).finally(() => {
    closeLoading.value = false
  })
}
const getClearingStatus = payment => {
  const status = payment['clearing_response']?.data?.status ?? null
  const time = payment['clearing_response']?.data?.['payment_time'] ?? null
  const url = payment['clearing_response']?.data?.['receipt_url'] ?? null
  if (!payment['clearing_id'] || status === 'new') {
    return 'В очереди'
  }
  if (status === 'done' || status === 'sent') {
    return `<a href="${url}" target="_blank"><span class="hint">Отправлено в <br>${time}</span></a>`
  }
  return 'Ошибка отправки'
}
const formattedDate = (value, short = false) => {
    let diff = moment(value).diff(moment(), 'days');
    let uint = number =>  Math.sqrt(Math.pow(number, 2));
    let date;
    if (uint(diff) > 1)
      date = short
        ? moment(value).locale('ru').format('D MMM в HH:mm')
        : moment(value).locale('ru').format('YYYY.MM.DD в HH:mm')
    else
      date = moment(value).locale('ru').calendar()

    return date;
  },
  formattedUsername = (s) => {
    return s ? s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } ) : '';
  },
  formattedBalance = (balance, penny = false) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let actualBalance = penny ? balance / 100 : balance
    let formattedBalance = formatter.format(actualBalance)
    return formattedBalance.replace(",00", "")
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  formattedPan = (pan) => {
    if (pan.length > 15)
      pan = pan.substr(0, 4) + ' ' + pan.substr(4, 4) + ' ' + pan.substr(8, 4) + ' ' + pan.substr(12, 4)
    return pan
  };

const panes = ref([
  {
    title: 'Платеж',
    content: 'payment',
    key: 'payment',
    closable: false,
  },
]);

onMounted(async () => {
  loading.value = true
  await apiClient.get(`/report/payment-view?id=${props.id}`).then(({data}) => {
    payment.value = data.data
    review.value = data.data['review']
    loading.value = false
    if (data.data['review']) {
      panes.value.push({
        title: 'Отзыв',
        content: 'review',
        key: 'review',
        closable: false,
      })
    }
    if (data.data.tax) {
      panes.value.push({
        title: 'Фискальный чек',
        content: 'tax',
        key: 'tax',
        closable: false,
      })
    }
    if (data.data['refunded'] && data.data['refunded']['receipt']) {
      panes.value.push({
        title: 'Отмененнный фиск. чек',
        content: 'refunded',
        key: 'refunded',
        closable: false,
      })
    }
    if (data.data.details && data.data.details.length !== 0) {
      orderDetails.value = new OrderDetails(data.data.details)
      panes.value.push({
        title: 'Детали счета',
        content: 'cheque',
        key: 'cheque',
        closable: false,
      })
    }
    if (data.data['invoice_data'] && Object.keys(data.data['invoice_data']).length !== 0) {
      panes.value.push({
        title: 'Статус инвойса',
        content: 'invoice',
        key: 'invoice',
        closable: false,
      })
    }
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    loading.value = false
  });
})

const tabClick = (value) => {
  if (value === 'cheque' && payment.value) {
    if (orderDetails.value.longitude && orderDetails.value.latitude) {
      window.ymaps.ready(function () {
        let myMap = new window.ymaps.Map("map", {
          center: [orderDetails.value['latitude'], orderDetails.value['longitude']],
          zoom: 16,
          controls: [],
        })

        myMap.geoObjects
          .add(new ymaps.Placemark([orderDetails.value['latitude'], orderDetails.value['longitude']], {
          }, {
            preset: 'islands#icon',
            iconColor: '#61c89e',
          }));

        myMap.events.add('click', function (e) {
          window.open(`https://yandex.ru/maps/?whatshere[point]=${orderDetails.value['longitude']},${orderDetails.value['latitude']}&whatshere[zoom]=16`, "_blank")
        });
      });
    }
  }
},
close = () => {
  emit('close')
},
showCancelModal = (payment) => {
  currentPayment.value = payment;
  cancelModalId.value = payment.id;
  cancelModal.value = true;
  commentText.value = null;
  hint.value = 'Внимание! Вы отменяете счет';
  if (parseInt(payment?.['tips_amount']) > 0) {
    hint.value = hint.value + ' и чаевые';
  }
},
closeCancelModal = () => {
  cancelModalId.value = 0
  commentText.value = null
  cancelModal.value = false
  currentPayment.value = null
  emit('close')
},
cancelPayment = () => {
  if (!commentText.value) {
    notification.warning({
      message: 'Поле "комментарий" объязательное',
    });
    return;
  }

  if (!currentPayment.value?.['payment_system']) {
    notification.warning({
      message: 'Не найдено поле "тип платежа" (payment_system)',
    });
    return;
  }
  const uuid = currentPayment.value?.['payment_uuid'];
  if (!uuid) {
    notification.warning({
      message: 'Не найдено поле "id платежа" (payment_uuid)',
    });
    return;
  }
  cancelLoading.value = true
  apiClient.post(`/payment/${currentPayment.value?.['id']}/${uuid}`, {
    comment: commentText.value,
    without_request: cancelWithoutRequest.value
  }).then(({data}) => {
    notification.success({
      message: data.data.message,
    });
  }).catch(() => {
    // console.log(err);
  }).finally(() => {
    cancelLoading.value = false
    closeCancelModal()
    emit('close');
  });
}
</script>

<style scoped src="./bill-details.css"></style>
